<template>
	<div class="animated fadeIn">
		<div class="row">
			<div class="col">
				<distributor-leaderboard />
			</div>
		</div>
	</div>
</template>
<script>
import DistributorLeaderboard from './components/DistributorLeaderboard';

export default {
	name: 'Leaderboard',
	components: {
		DistributorLeaderboard,
	},
};
</script>
